<template>
  <div
    class="list-switch"
    tabindex="0"
    @keydown.enter="
      () => {
        if (!isOpen) {
          isOpen = true;
        } else {
          select(listedOptions[keyboardIndex]);
        }
      }
    "
    @keydown.down.prevent="
      () => {
        isOpen = true;
        keyboardIndex = Math.min(keyboardIndex + 1, listedOptions.length - 1);
      }
    "
    @keydown.up.prevent="
      () => {
        if (isOpen) {
          keyboardIndex = Math.max(keyboardIndex - 1, 0);
        }
      }
    "
    v-set-colors="{ background }"
    v-class-mod:list-switch="[{ isOpen, scaleToOptions, hasIcon: !!icon }, size ?? 'large']"
  >
    <span class="list-switch__label" @click="isOpen = true">
      <span v-if="icon" :class="`h56-icons-before--${icon}`" class="list-switch__icon" />
      <span class="list-switch__label-text">
        {{ selectedOption?.title || "-" }}
        <span v-if="scaleToOptions" class="list-switch__scale">
          <span v-for="option in listedOptions" class="list-switch__scale-options" :key="option.id">
            {{ option.title }}
          </span>
        </span>
      </span>
    </span>

    <div
      v-if="isOpen"
      class="list-switch__options"
      v-click-outside="{
        callback: () => (isOpen = false),
        enableCloseOnEsc: true,
      }"
      v-lock-focus
    >
      <span
        v-for="(option, i) in listedOptions"
        class="list-switch__option"
        @click="() => select(option)"
        @keydown.enter="() => select(option)"
        :key="option.id"
        v-class-mod:list-switch__option="{ isKeyboardIndex: i === keyboardIndex }"
      >
        {{ option.title }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

import { vClickOutside, vLockFocus, vSetColors } from "@horizon56/directives";
import { IconName } from "@horizon56/fonts/types";
import { ColorName } from "@horizon56/styles/types";

interface Option {
  id: any;
  title: string;
  click: () => void;
}

const props = defineProps<{
  icon?: IconName;
  size?: "small" | "medium" | "large";
  options: Option[];
  value: any;
  scaleToOptions?: true;
  background?: ColorName;
}>();

const listedOptions = computed(() => [
  ...(selectedOption.value ? [selectedOption.value] : []),
  ...props.options.filter((o) => o.id !== selectedOption.value?.id),
]);

const isOpen = ref(false);
const selectedOption = computed(() => props.options.find((o) => o.id === props.value));
const keyboardIndex = ref(0);

const select = (option: Option) => {
  option.click();
  isOpen.value = false;
};

watch(
  () => isOpen.value,
  (isOpen) => {
    if (!isOpen) {
      keyboardIndex.value = 0;
    }
  },
);
</script>

<style lang="scss" scoped>
.list-switch {
  $block: &;
  display: flex;
  user-select: none;
  position: relative;
  border-radius: var(--app-radius-medium);
  &:not(&--isOpen) {
    @include focus-outline;
  }
  &--isOpen {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  &__options {
    background: var(--menu-bg);
  }
  &__option,
  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 12px;
    height: var(--option-height, var(--app-button-height-large));
    flex-flow: row nowrap;
    &--isKeyboardIndex,
    &:hover {
      background: var(--black-20);
      #{$block}__icon {
        color: var(--black-90);
      }
    }
  }
  &--small {
    --option-height: var(--app-button-height-small);
  }
  &--medium {
    --option-height: var(--app-button-height-medium);
  }
  &__label {
    justify-content: flex-start;
    text-align: left;
    background: var(--list-switch-label-bg, var(---menu-bg));
    &-text {
      display: flex;
      flex-flow: column;
      margin-right: auto;
    }
  }
  &__icon {
    margin-right: 4px;
    color: var(--black-50);
    font-size: var(--app-icon-size-medium);
  }
  &__label {
    border-radius: var(--list-switch-border, var(--app-radius-medium));
    @include focus-outline();
  }
  &__label,
  &__option:first-child {
    width: 100%;
    justify-content: space-between;
    @include icon-after($icon-arrow_drop_down) {
      font-size: var(--app-icon-size-medium);
      margin: 0 4px;
      color: var(--black-50);
    }
    &:hover:after {
      color: var(--black-90);
    }
  }
  &__label-text {
    margin-top: 2px;
  }
  &__options {
    display: flex;
    flex-flow: column;
    position: absolute;
    left: -1px;
    top: -1px;
    overflow: hidden;
    border: 1px solid var(--black-10);
    min-width: calc(100% + 2px);
    border-radius: var(--app-radius-medium);
    z-index: 1;
  }
  &--isOpen #{&}__option:first-child:after {
    transform: rotate(180deg);
  }
  &__scale {
    display: flex;
    flex-flow: column;
    height: 0px;
    overflow: hidden;
    &-option {
      white-space: nowrap;
    }
  }
}
</style>
